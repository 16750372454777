import React from 'react';

type Props = {
    name?: string;
    paddingLeft?: number;
    paddingRight?: number;
    isActive?: boolean;
}

function Event({ name, paddingLeft, paddingRight, isActive }: Props, ref: React.Ref<HTMLDivElement>) {
    
    return (
        <div ref={ref} className={(paddingLeft ? 'border-l ': '') + (paddingRight ? 'border-r ' : '') + 'border-t border-b border-gray-500 p-2 whitespace-nowrap bg-white bg-opacity-80 flex flex-col justify-center'} style={{
            width: '100%',
            height: '100%',
            paddingLeft: paddingLeft ? `calc(0.5rem + ${paddingLeft}px)` : '0.5rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}>
            <span className={'ml-0' + (isActive ? ' ml-2' : '')}>{name}</span>
        </div>
    );
}

export default React.forwardRef(Event);
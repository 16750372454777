import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackButton, CreateButton } from '../components/CustomButton';
import CustomDatePicker from '../components/CustomDatePicker';
import CustomInput from '../components/CustomInput';
import { fetcher } from '../request';
import { eventToState, getStartDateDB, getEndDateDB } from '../helpers'
import CustomCheckbox from '../components/CustomCheckbox';

function PersonNew() {

    const { state } = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [targetRevenuePerHour, setTargetRevenuePerHour] = useState(0.0);
    const [costPerHour, setCostPerHour] = useState(0.0);
    const [contractor, setContractor] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const req = fetcher(process.env.REACT_APP_API_URL + '/Person', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Name: name,
                StartTime: getStartDateDB(startDate) || null,
                EndTime: getEndDateDB(endDate, 1) || null,
                TargetRevenuePerHour: targetRevenuePerHour,
                CostPerHour: costPerHour,
                Contractor: contractor ? 1 : 0,
                Active: 1,
            })
        });
        req.then(res => {
            if (res.status === 200) {
                navigate('..');
            }
        });
    
        console.log(name, startDate, endDate);
    }

    return (
        <div>
            <BackButton state = { state } navigate = { navigate } />
            <h1 className='text-2xl font-bold my-5'>Neuer Mitarbeiter</h1>
            <CustomInput id='name' label='Name' type='text' onChange={eventToState(setName)} />
            <CustomDatePicker id='start-date' type='date' label='Startdatum' onChange={eventToState(setStartDate)} />
            <CustomDatePicker id='end-date' type='date' label='Enddatum' onChange={eventToState(setEndDate)} />
            <CustomInput id='targetRevenue' label='Zielumsatz pro Stunde' type='number' onChange={eventToState(setTargetRevenuePerHour)} />
            <CustomInput id='CostPerHour' label='Kosten pro Stunde' type='number' onChange={eventToState(setCostPerHour)} />
            <CustomCheckbox id='Contractor' label='Arbeitet im Stundenlohn' onChange={(e) => setContractor(e.target.checked)} />
            <CreateButton className='mt-6' onClick={handleSubmit} />
        </div>
    );
}

export default PersonNew;
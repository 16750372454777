import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { db_Site } from '../models';
import { CreateButton, EditButton, TrashButton } from '../components/CustomButton';
import { fetcher } from '../request';
import { getStartDate, getEndDate } from '../helpers';
import moment from 'moment';

type Props = {
    mode: 'open' | 'closed',
}

function SitesList({mode}: Props) {

    const navigate = useNavigate();
    let [sites, setSites] = useState<db_Site[]>([]);

    useEffect(() => {
        const req = fetcher(process.env.REACT_APP_API_URL + '/Site');
        //only show active sites sorted by id
        req.then(res => res.json()).then((data: db_Site[]) => 
        {
            // only select sites which have no fixed end date or it is in the future
            let new_data : db_Site[] = [];
            if(mode === 'open')
            {
                new_data = data.filter(s => s.EndTime == null || moment.utc(s.EndTime) > moment()).sort((a, b) => b.id_Site - a.id_Site)
            }
            else if (mode === 'closed')
            {
                new_data = data.filter(s => s.EndTime != null && moment.utc(s.EndTime) <= moment()).sort((a, b) => b.id_Site - a.id_Site)
            }
            else
            {
                console.log('invalid mode in SiteList specified')
            }
            setSites(new_data);
        });
    }, [mode]);

    const deleteSite = (id: number) => {
        if(window.confirm('Auftrag wirklich löschen?')) {
            fetcher(process.env.REACT_APP_API_URL + '/Site/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Active: 0
                })
            });
            setSites(sites.filter(site => site.id_Site !== id));
        }
    }

    const row = (site: db_Site, isEven: boolean) => {
        return (
            <div key={site.id_Site} className={(isEven ? 'bg-white ' : 'bg-gray-50 ') + 'px-2 py-3 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6'}>
                <Link to={'./' + site.id_Site} className="mt-1 text-sm text-gray-900">{site.Name}</Link>
                <Link to={'./' + site.id_Site} className="mt-1 text-sm text-gray-900">{getStartDate(site.StartTime)}</Link>
                <Link to={'./' + site.id_Site} className="mt-1 text-sm text-gray-900">{getEndDate(site.EndTime)}</Link>
                <Link to={'./' + site.id_Site} className="mt-1 text-sm text-gray-900">{site.BudgetHours}</Link>
                <Link to={'./' + site.id_Site} className="mt-1 text-sm text-gray-900">{site.Budget}</Link>
                <Link to={'./' + site.id_Site} className="mt-1 text-sm text-gray-900">{site.ExteriorWork ? '✅' : '🛑'}</Link>
                <dd className="mt-1 text-sm text-gray-900  space-x-2">
                    <EditButton onClick={(e) => { e.stopPropagation(); navigate(`./${site.id_Site}/edit`)}} />
                    <TrashButton onClick={() => deleteSite(site.id_Site)} />
                </dd>
            </div>
        );
    }

    return (
        <div>
            <div className='flex item-center justify-between my-5'>
                <h1 className='text-2xl font-bold'>{(mode === 'open') ? "Aufträge offen" : "Aufträge abgeschlossen"}</h1>
                <Link to='./new'>
                    <CreateButton />
                </Link>
            </div>
            <dl className='shadow-xl'>
                <div className="bg-gray-50 px-2 py-3 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6 border-y border-gray-200">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dt className="text-sm font-medium text-gray-500">Startdatum</dt>
                    <dt className="text-sm font-medium text-gray-500">Enddatum</dt>
                    <dt className="text-sm font-medium text-gray-500">Budgetierte Stunden</dt>
                    <dt className="text-sm font-medium text-gray-500">Budget</dt>
                    <dt className="text-sm font-medium text-gray-500">Malerarbeit aussen</dt>
                    <dt className="text-sm font-medium text-gray-500">Aktionen</dt>
                </div>
                {sites.map((site, index) => (row(site, index % 2 === 0)))}
            </dl>
        </div>
    );
}

export default SitesList;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import reportWebVitals from './reportWebVitals';
import "@fontsource/roboto";

import App from './App';
import Sites from './sites/Sites';
import SiteDetail from './sites/SiteDetail';
import SiteList from './sites/SitesList';
import SiteNew from './sites/SiteNew';
import Persons from './persons/Persons';
import PersonDetail from './persons/PersonDetail';
import PersonList from './persons/PersonsList';
import PersonNew from './persons/PersonNew';
import SiteOverview from './Overview';
import Settings from './Settings';
import Login from './Login';
import AuthGaurd from './AuthGuard';
import PersonEdit from './persons/PersonEdit';
import SiteEdit from './sites/SiteEdit';
import SitePlanning from './planning-v2/SitePlanning';
import PersonPlanning from './planning-v2/PersonPlanning';
import { ToastContainer } from 'react-toastify';
import About from './about/about';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
        <ToastContainer theme='light' style={{ width: "500px" }} />
        <BrowserRouter>
        <Routes>
          <Route index element={<AuthGaurd><Navigate to='/site-planning' replace /></AuthGaurd>} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<h1>404</h1>} />
          <Route path="/" element={<AuthGaurd><App /></AuthGaurd>}>
            <Route path="sites-open" element={<Sites />}>
              <Route path=":siteId" element={<SiteDetail />} />
              <Route path=":siteId/edit" element={<SiteEdit />} />
              <Route path="new" element={<SiteNew />} />
              <Route index element={<SiteList mode='open' />} />
            </Route>
            <Route path="sites-closed" element={<Sites />}>
              <Route path=":siteId" element={<SiteDetail />} />
              <Route path=":siteId/edit" element={<SiteEdit />} />
              <Route path="new" element={<SiteNew />} />
              <Route index element={<SiteList mode='closed' />} />
            </Route>
            <Route path="persons" element={<Persons />}>
              <Route path=":personId" element={<PersonDetail />} />
              <Route path=":personId/edit" element={<PersonEdit />} />
              <Route path="new" element={<PersonNew />} />
              <Route index element={<PersonList />} />
            </Route>
            <Route path="person-planning" element={<PersonPlanning />} />
            <Route path="site-planning" element={<SitePlanning />} />
            <Route path="month" element={<SiteOverview />}>
              <Route path="*" element={<SiteOverview />} />
            </Route>
            <Route path="settings" element={<Settings />} />
            <Route path="about" element={<About />} />
            <Route path="*" element={<h1>404</h1>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

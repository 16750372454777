import { db_Person, db_Site } from '../models';
import { viewModes } from '../models';
import { selectPersons } from '../redux/persons/personsSlice';
import { selectPersonSitesByPersonIndex } from '../redux/personSites/personSitesSlice';
import { selectSiteIndex } from '../redux/sites/sitesSlice';
import Planner from './Planner';

function PersonPlanning() {
    const personsSelector = selectPersons;
    const eventsSelector = selectPersonSitesByPersonIndex;
    const siteIndexSelector = selectSiteIndex;

    return (
        <Planner<db_Person, db_Site> mode={viewModes.Person} resourcesSelector={personsSelector} eventsSelector={eventsSelector} indexSelector={siteIndexSelector} />
    );
}

export default PersonPlanning;

type Props = {
    id?: string,
    label?: string,
    type?: string,
    placeholder?: string,
    value?: string | number,
    context?: any,
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    numeric?: boolean,
    className?: string
}

function CustomInput({ id, label, type, placeholder, value, onKeyDown, onChange, className }: Props) {

    return (
        <div className={className}>
            <label htmlFor={id} className='block text-sm font-medium text-gray-500'>{label}</label>
            <input id={id} className='block mt-1 appearance-none border-2 border-gray-200 rounded-lg py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 mb-3'
                type={type} placeholder={placeholder} 
                value={value === null ? '' : value} 
                onKeyDown={onKeyDown}
                onChange={onChange} />
        </div>
    )
}

export default CustomInput;
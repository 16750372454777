import { SaveIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CustomButton, BackButton } from '../components/CustomButton';
import CustomDatePicker from '../components/CustomDatePicker';
import CustomInput from '../components/CustomInput';
import { db_Site } from '../models';
import { fetcher } from '../request';
import { eventToState, getStartDateDB, getEndDateDB, getDateFromKW, getKWFromDate } from '../helpers'
import CustomCheckbox from '../components/CustomCheckbox';

function SiteEdit() {

    let { siteId } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [nameShort, setNameShort] = useState('');
    const [startDate, setStartDate] = useState('');
    const [startKW, setStartKW] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endKW, setEndKW] = useState('');
    const [hours, setHours] = useState('');
    const [budget, setBudget] = useState('');
    const [exteriorWork, setExteriorWork] = useState(false);

    useEffect(() => {
        const req = fetcher(process.env.REACT_APP_API_URL + '/Site/' + siteId);
        req.then(res => res.json()).then((data: db_Site) => {
            setName(data.Name);
            setNameShort(data.NameShort);
            setStartDate(getStartDateDB(data.StartTime));
            setStartKW(getKWFromDate(moment(data.StartTime)));
            setEndDate(getEndDateDB(data.EndTime, -1));
            setEndKW(getKWFromDate(moment(getEndDateDB(data.EndTime, -1))));
            setHours(data.BudgetHours.toFixed(0));
            setBudget(data.Budget?.toFixed(0));
            setExteriorWork(data.ExteriorWork === 1);
        });
    }, [siteId]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        fetcher(process.env.REACT_APP_API_URL + '/Site/' + siteId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Name: name,
                NameShort: nameShort,
                StartTime: getStartDateDB(startDate),
                EndTime: endDate !== '' ? getEndDateDB(endDate, 1) : null,
                BudgetHours: hours,
                Budget: budget,
                ExteriorWork: exteriorWork ? 1 : 0,
            })
        })
        .then(res => {
            if (res.status === 200) {
                navigate(`../${siteId}`);
            }
        });
    }
    return (
        <div>
            <BackButton state = { state } navigate = { navigate } />
            <h1 className='text-2xl font-bold my-5'>Auftrag bearbeiten</h1>
            <CustomInput id='name' label='Name' type='text' value={name} onChange={eventToState(setName)} />
            <CustomInput id='name-short' label='Auftragsnummer' type='text' value={nameShort} onChange={eventToState(setNameShort)} />
            <div className='flex space-x-5'>
                <CustomDatePicker id='start-date' type='date' label='Startdatum' value={startDate} onChange={ (e) => 
                {
                    setStartDate(e.target.value);
                    setStartKW(getKWFromDate(moment(e.target.value)).toString());
                } } />
                <div className='block text-sm font-medium text-gray-500'> oder: </div>
                <CustomInput id='start-date' label='Kalenderwoche' type='number' value={startKW} onChange={ (e) => 
                { 
                    setStartKW(e.target.value); 
                    setStartDate(getDateFromKW(e.target.value)); 
                } } />
            </div>
            <div className='flex space-x-5'>
                <CustomDatePicker id='end-date' type='date' label='Enddatum' value={endDate} onChange={ (e) =>
                {
                    setEndDate(e.target.value);
                    setEndKW(getKWFromDate(moment(e.target.value)).toString());
                } } />
                <div className='block text-sm font-medium text-gray-500'> oder: </div>
                <CustomInput id='end-date' label='Kalenderwoche' type='number' value={endKW} onChange={ (e) => 
                { 
                    setEndKW(e.target.value); 
                    setEndDate(getDateFromKW(e.target.value)); 
                } } />
            </div>
            <CustomInput id='hours' label='Budgetierte Stunden' type='number' value={hours} onChange={eventToState(setHours)} />
            <CustomInput id='budget' label='Budget (CHF)' type='number' value={budget} onChange={eventToState(setBudget)} />
            <CustomCheckbox id='outdoorWork' label='Malerarbeit aussen' checked={exteriorWork} onChange={(e) => setExteriorWork(e.target.checked)} />
            <CustomButton className='mt-6' Icon={SaveIcon} onClick={handleSubmit}>Speichern</CustomButton>
        </div>
    );
}

export default SiteEdit;
import React, { useRef } from 'react';
import { useDragLayer, useDrop } from 'react-dnd';
import { v_PersonSite } from '../models';
import { ItemTypes } from './dndModels';
import EventDragPreview from './EventDragPreview';

type Props = {
    rows: number,
    cols: number,
    moveResizeCell: (personSite: v_PersonSite, startColDiff: number, endColDiff: number, rowDiff: number, row: number) => Promise<void>,
}

function CustomDragLayer({ rows, cols, moveResizeCell }: Props) {

    const ref = useRef<HTMLDivElement>(null);

    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    const [, drop] = useDrop(
        () => ({
          accept: ItemTypes.EVENT,
          drop(item: any, monitor) {
            const delta = monitor.getDifferenceFromInitialOffset();

            const width = ref.current ? ref.current.clientWidth : 0;
            const height = ref.current ? ref.current.clientHeight : 0;
            const cellWidth = width / cols;
            const cellHeight = height / rows;

            if (!delta) {
                return null;
            }

            const colDelta = Math.round(delta.x  / cellWidth);
            const rowDelta = Math.round(delta.y / cellHeight);

            if (colDelta !== 0 || rowDelta !== 0) {
                moveResizeCell(item.personSite, colDelta, colDelta, rowDelta, item.gridRow);
            }
            return undefined
          },
        }),
        [moveResizeCell],
      );

    function renderItem() {
        let deltaX, deltaY;
        if (initialOffset && currentOffset) {
            deltaX = currentOffset.x - initialOffset.x;
            deltaY = currentOffset.y - initialOffset.y;
        } else if (initialOffset) {
            deltaX = 0;
            deltaY = 0;
        } else {
            return null;
        }

        switch (itemType) {
                case ItemTypes.EVENT:
                    const props = item.props;
                    const width = ref.current ? ref.current.clientWidth : 0;
                    const height = ref.current ? ref.current.clientHeight : 0;
                    const cellWidth = width / cols;
                    const cellHeight = height / rows;
                    const x = deltaX + (item.actualColumn - 2) * cellWidth;
                    const y = deltaY + (item.gridRow - 2) * cellHeight;
                    // const y = deltaY + (item.gridRow - 2) * cellHeight;
                    return <EventDragPreview
                                x={x}
                                y={y}
                                cellWidth={cellWidth}
                                cellHeight={cellHeight}
                                gridRow={item.gridRow - 1}
                                gridColumn={item.gridColumn - 1}
                                gridColumnSpan={item.gridColumnSpan}
                                actualColumn={item.actualColumn - 1}
                                actualColumnSpan={item.actualColumnSpan}
                                props={props} />
            }
        return null
    }

    const onResizeDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        const rawData = e.dataTransfer.getData('text/plain');
        if (rawData) {
            const eventData = JSON.parse(e.dataTransfer.getData('text/plain'));
            if (eventData.type === ItemTypes.EVENT + '-resize-right') {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    }

    drop(ref);

    return <div ref={ref} onDragOver={onResizeDragOver} style={{
        gridRow: `2 / ${rows + 2}`,
        gridColumn: `2 / ${cols + 2}`,
        zIndex: 1,
        pointerEvents: isDragging ? 'auto' : 'none',
        overflow: 'hidden',
        position: 'relative',
    }}>
        { isDragging ? renderItem() : null }
    </div>
}

export default CustomDragLayer;
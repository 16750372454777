import './App.css';
import {useEffect, useState} from 'react';
import { Link, Outlet } from 'react-router-dom';
import CoolNavLink from './components/CoolNavLink';
import 'react-toastify/dist/ReactToastify.css';
import store from './redux/store';
import { loadSettings } from './redux/globals/globalsSlice';
import { activePersonSiteIdSet } from './redux/personSites/personSitesSlice';
import Hamburger from './components/Hamburger';

function App() {

  const [isCollapsed, setIsCollapsed] = useState(false);

  //on load
  useEffect(() => {
    if(process.env.REACT_APP_TENANT === 'schneider') {
      document.body.style.backgroundColor = '#D1D2D4';
      document.title = "Workmanager - Schneider";
    }
    else {
      document.body.style.backgroundColor = '#C9CEBD';
      document.title = "Workmanager - Schmocker";
    }
    store.dispatch(loadSettings);
  }, []);

  return (
    <div className='main-divide min-h-screen'>
      <div className={`flex flex-col flex-row justify-between h-screen sticky top-0 transition-all duration-500 ${isCollapsed ? 'w-[40px] min-w-[40px]' : 'w-[15%] min-w-[150px]'}`}>
        <nav id='space-deactivate-nav' className={`flex flex-col space-y-1 mt-5 transition-all duration-500 ${isCollapsed ? 'w-0' : 'w-full'}`} onClick={(e) => store.dispatch(activePersonSiteIdSet(-1)) }>
          <Link to='/' style={{ margin: 0 }} className='font-bold nav-image flex items-center justify-center h-[70px]'>
            <img className='max-h-full max-w-full pr-8 pl-2 pt-2 pb-2' src={(process.env.REACT_APP_TENANT === 'schneider' ? '/malerei-schneider.svg' : '/malerei-schmocker.svg')} alt='Meier Schmocker AG' />
          </Link>
          <CoolNavLink align='left' isCollapsed={isCollapsed} to='/site-planning'>Arbeitsplanung Aufträge</CoolNavLink>
          <CoolNavLink align='left' isCollapsed={isCollapsed} to='/sites-open'>Aufträge offen</CoolNavLink>
          <CoolNavLink align='left' isCollapsed={isCollapsed} to='/sites-closed'>Aufträge abgeschlossen</CoolNavLink>
          <CoolNavLink align='left' isCollapsed={isCollapsed} to='/person-planning'>Arbeitsplanung Mitarbeiter</CoolNavLink>
          <CoolNavLink align='left' isCollapsed={isCollapsed} to='/persons'>Mitarbeiter</CoolNavLink>
          <CoolNavLink align='left' isCollapsed={isCollapsed} to='/month/finance_all'>Monatsübersichten</CoolNavLink>
          <CoolNavLink align='left' isCollapsed={isCollapsed} to='/settings'>Einstellungen</CoolNavLink>
          <CoolNavLink align='left' isCollapsed={isCollapsed} to='/login'>Logout</CoolNavLink>
        </nav>
        <div className='flex flex-row flex-grow' onClick={(e) => store.dispatch(activePersonSiteIdSet(-1)) } />
        <CoolNavLink align='left' isCollapsed={isCollapsed} to='/about'>Impressum</CoolNavLink>
        <Hamburger isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      </div>
      <main className={`flex flex-col p-[20px] transition-all duration-500 ${isCollapsed ? 'w-[calc(100%-40px)]' : 'w-[85%]'}`}>
        <Outlet />
        <div id='space-deactivate' className='flex-grow' onClick={(e) => store.dispatch(activePersonSiteIdSet(-1)) } />
      </main>
    </div>
  );
}

export default App;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Absence, NewAbsence } from '../../models';
import { fetcher } from '../../request';
import type { RootState, AppDispatch } from '../store';

type AbsencesState = {
  absencesByPersonId: Record<string, Absence[]>,
  absencesByPersonWeekday: Record<string, Record<string, Absence>>,
};

const initialState: AbsencesState = {
  absencesByPersonId: {},
  absencesByPersonWeekday: {},
};

export const absencesSlice = createSlice({
  name: 'absences',
  initialState,
  reducers: {
    absenceAdded: (state, action: PayloadAction<Absence>) => {
      if (!state.absencesByPersonId[action.payload.id_Person]) {
        state.absencesByPersonId[action.payload.id_Person] = [action.payload];
      } else {
        state.absencesByPersonId[action.payload.id_Person].push(action.payload);
      }

      if (!state.absencesByPersonWeekday[action.payload.id_Person]) {
        state.absencesByPersonWeekday[action.payload.id_Person] = {};
      }
      if (!state.absencesByPersonWeekday[action.payload.id_Person][action.payload.DayOfWeek]) {
        state.absencesByPersonWeekday[action.payload.id_Person][action.payload.DayOfWeek] = action.payload;
      } else if (action.payload.StartTime >= state.absencesByPersonWeekday[action.payload.id_Person][action.payload.DayOfWeek].StartTime) {
        state.absencesByPersonWeekday[action.payload.id_Person][action.payload.DayOfWeek] = action.payload;
      }
    },
    absenceDeleted: (state, action: PayloadAction<Absence>) => {
      if (state.absencesByPersonId[action.payload.id_Person]) {
        state.absencesByPersonId[action.payload.id_Person] = state.absencesByPersonId[action.payload.id_Person].filter(absence => absence.id_Absences !== action.payload.id_Absences);
      }
      if (
        state.absencesByPersonWeekday[action.payload.id_Person] &&
        state.absencesByPersonWeekday[action.payload.id_Person][action.payload.DayOfWeek] &&
        state.absencesByPersonWeekday[action.payload.id_Person][action.payload.DayOfWeek].id_Absences === action.payload.id_Absences
      ) {
        delete state.absencesByPersonWeekday[action.payload.id_Person][action.payload.DayOfWeek];
      }
    },
    absenceUpdated: (state, action: PayloadAction<Absence>) => {
      if (state.absencesByPersonId[action.payload.id_Person]) {
        const index = state.absencesByPersonId[action.payload.id_Person].findIndex((absence) => absence.id_Absences === action.payload.id_Absences);
        if (index !== -1) {
          state.absencesByPersonId[action.payload.id_Person][index] = action.payload;
        }
      }
    },
    absencesLoaded: (state, action: PayloadAction<Absence[]>) => {
      state.absencesByPersonId = action.payload.reduce((absencesByPersonId, absence) => {
        if (!absencesByPersonId[absence.id_Person]) {
          absencesByPersonId[absence.id_Person] = [absence];
        } else {
          absencesByPersonId[absence.id_Person].push(absence);
        }
        return absencesByPersonId;
      }, {} as AbsencesState['absencesByPersonId']);

      state.absencesByPersonWeekday = action.payload.reduce((absencesByPersonWeekday, absence) => {
        if (!absencesByPersonWeekday[absence.id_Person]) {
          absencesByPersonWeekday[absence.id_Person] = {};
        }
        if (!absencesByPersonWeekday[absence.id_Person][absence.DayOfWeek]) {
          absencesByPersonWeekday[absence.id_Person][absence.DayOfWeek] = absence;
        } else if (absence.StartTime >= absencesByPersonWeekday[absence.id_Person][absence.DayOfWeek].StartTime) {
          absencesByPersonWeekday[absence.id_Person][absence.DayOfWeek] = absence;
        }
        return absencesByPersonWeekday;
      }, {} as AbsencesState['absencesByPersonWeekday']);
    },
  },
});

export const { absenceAdded, absenceDeleted, absenceUpdated, absencesLoaded } = absencesSlice.actions;

export async function loadAbsences(dispatch: AppDispatch) {
  const response = await fetcher(process.env.REACT_APP_API_URL + '/Absences');
  const absences = await response.json();
  dispatch(absencesLoaded(absences));
}

export function addAbsence(absence: NewAbsence) {
  return async (dispatch: AppDispatch) => {
    const response = await fetcher(process.env.REACT_APP_API_URL + '/Absences', {   
      method: 'POST',
      body: JSON.stringify(absence),
    });
    const addedAbsence = await response.json();
    dispatch(absenceAdded(addedAbsence));
  };
}

export async function deleteAbsence(dispatch: AppDispatch, absence: Absence) {
  await fetcher(process.env.REACT_APP_API_URL + '/Absences', {
    method: 'PUT',
    body: JSON.stringify({
      Active: 0
    }),
  });
  dispatch(absenceDeleted(absence));
}

export function updateAbsence(absence: Absence) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const newAbsence = {
        ...absence,
        id_Absences: undefined,
    };
    await fetcher(process.env.REACT_APP_API_URL + '/Absences/' + absence.id_Absences, {
      method: 'PUT',
      body: JSON.stringify(newAbsence),
    });
    dispatch(absenceUpdated(absence));
  };
}

export const selectAbsencesIndex = (state: RootState) => state.absences.absencesByPersonId;
export const selectAbsencesByPersonId = (personId: string) => (state: RootState) => state.absences.absencesByPersonId[personId];
export const selectAbsencesWeekdayIndex = (state: RootState) => state.absences.absencesByPersonWeekday;
export const selectAbsencesWeekdayIndexByPersonId = (personId: string) => (state: RootState) => state.absences.absencesByPersonWeekday[personId];

export default absencesSlice.reducer;
type db_Person = {
    id_Person: number,
    Name: string,
    StartTime: string,
    EndTime: string,
    CostPerHour: number,
    TargetRevenuePerHour: number,
    Contractor: number,
    Priority: number,
    Active: number,
}

type v_PersonSite = db_PersonSite & {
    RowIndex: number,
}

type db_PersonSite = NewPersonSite & {
    id_PersonSite: number,
}

type NewPersonSite = {
    id_Person: number,
    id_Site: number,
    StartTime: string,
    EndTime: string,
    Active: number,
    New?: boolean,
}

type db_Site = {
    id_Site: number,
    Name: string,
    NameShort: string,
    Budget: number,
    StartTime: string,
    EndTime: string,
    EndTimeBudget: string,
    BudgetHours: number,
    Priority: number,
    ExteriorWork: number,
    Active: boolean,
    Progress?: number,
    days_diff?: number,
    days_ratio?: number,
}

type db_Month = {
    DaysThisMonth: number,
    Month: string,
    Name: string,
    StartTime: string,
    EndTime: string,
}

type db_Global = {
    id_Globals: number,
    Name: string,
    Value: string,
    ChangeableOnline?: boolean,
    Active: boolean,
}

type db_Login = {
    id_Login: number,
    Username: string,
    Password: string,
    Active: boolean,
}

type db_Availability = {
    id_Person: number,
    Name: string,
    CostPerHour: number,
    TargetRevenuePerHour: number,
    Contractor: number,
    Days: number,
    DaysInclHolidays: number,
}

type Absence = NewAbsence & {
    id_Absences: number,
}

type NewAbsence = {
    id_Person: number,
    StartTime: string,
    DayOfWeek: string,
    Available: 0|1,
    Active: 0|1,
}

type db_Holiday = {
    id_Holidays: number,
    id_Person: number,
    StartTime: string,
    EndTime: string,
    RecurringIntervalDays: string,
    RecurringEndTime: string,
    Active: boolean,
}

export enum viewModes{
    None,
    Overview_Finance_All,
    Overview_Finance_Sites,
    Overview_Load_Availability,
    Overview_Load_People,
    Overview_Availability_People,
    Person,
    Site,
}

export type {
    db_Person,
    db_Site,
    db_PersonSite,
    v_PersonSite,
    NewPersonSite,
    db_Month,
    db_Global,
    db_Login,
    db_Availability,
    Absence,
    NewAbsence,
    db_Holiday,
}





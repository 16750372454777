import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { db_Site } from '../../models';
import { fetcher } from '../../request';
import type { RootState, AppDispatch } from '../store';

type SitesState = {
  sites: db_Site[],
  sitesById: Record<string, db_Site>,
};

const initialState: SitesState = {
  sites: [],
  sitesById: {},
};

export const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    siteAdded: (state, action: PayloadAction<db_Site>) => {
        state.sites.push(action.payload);
        state.sitesById[action.payload.id_Site] = action.payload;
    },
    siteDeleted: (state, action: PayloadAction<db_Site>) => {
        state.sites = state.sites.filter(site => site.id_Site !== action.payload.id_Site);
        delete state.sitesById[action.payload.id_Site];
    },
    siteUpdated: (state, action: PayloadAction<db_Site>) => {
        const index = state.sites.findIndex((site) => site.id_Site === action.payload.id_Site);
        if (index !== -1) {
            state.sites[index] = action.payload;
            state.sitesById[action.payload.id_Site] = action.payload;
        }
    },
    sitesLoaded: (state, action: PayloadAction<db_Site[]>) => {
        state.sites = action.payload;
        state.sitesById = action.payload.reduce((sitesById, site) => {
            sitesById[site.id_Site] = site;
            return sitesById;
        }, {} as SitesState['sitesById']);
    },
  },
});

export const { siteAdded, siteDeleted, siteUpdated, sitesLoaded } = sitesSlice.actions;

export async function loadSites(dispatch: AppDispatch) 
{
    const response = await fetcher(process.env.REACT_APP_API_URL + '/Site');
    const sites: db_Site[] = await response.json();
    const sitesWithProgress = await Promise.all(sites.map(async (site: db_Site) => {
        const resProgress = await fetcher(process.env.REACT_APP_API_URL + `/Site/${site.id_Site}/Progress`);
        site.Progress = await resProgress.json();
        return site;
    }));
    dispatch(sitesLoaded(sitesWithProgress));
}

export async function addSite(dispatch: AppDispatch, site: db_Site) {
    const response = await fetcher(process.env.REACT_APP_API_URL + '/Site', {
        method: 'POST',
        body: JSON.stringify(site),
    });
    const addedSite = await response.json();
    dispatch(siteAdded(addedSite));
}

export async function deleteSite(dispatch: AppDispatch, site: db_Site) {
    await fetcher(process.env.REACT_APP_API_URL + '/Site/' + site.id_Site, {
        method: 'PUT',
        body: JSON.stringify({
            Active: 0
        }),
    });
    dispatch(siteDeleted(site));
}

export async function updateSite(dispatch: AppDispatch, site: db_Site) {
    const newSite = {
        ...site,
        id_Site: undefined
    };
    const response = await fetcher(process.env.REACT_APP_API_URL + '/Site/' + site.id_Site, {
        method: 'PUT',
        body: JSON.stringify(newSite),
    });
    const updatedSite = await response.json();
    dispatch(siteUpdated(updatedSite));
}

export function updateSiteProgress(site: db_Site) {
    return async (dispatch: AppDispatch) => {
        const newSite = { ...site };
        //update Progress
        let res = await fetcher(process.env.REACT_APP_API_URL + `/Site/${site.id_Site}/Progress`);
        newSite.Progress = await res.json();
        //update EndTimeBudget
        res = await fetcher(process.env.REACT_APP_API_URL + `/Site/${site.id_Site}`);
        const siteFromDb = await res.json();
        newSite.EndTimeBudget = siteFromDb.EndTimeBudget;
        dispatch(siteUpdated(newSite));
    }
}

export const selectSites = (state: RootState) => state.sites.sites;
export const selectSiteIndex = (state: RootState) => state.sites.sitesById;
export const selectSiteById = (id: number) => (state: RootState) => state.sites.sitesById[id];

export default sitesSlice.reducer;
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { db_Person } from '../models';
import { CreateButton, EditButton, TrashButton } from '../components/CustomButton';
import { fetcher } from '../request';
import { getStartDate, getEndDate } from '../helpers';

function PersonsList() {
    let [persons, setPersons] = useState<db_Person[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getPersons = async () => {
            const res = await fetcher(process.env.REACT_APP_API_URL + '/Person');
            let data = await res.json();
            setPersons(data);
        }
        getPersons();
    }, []);

    const deletePerson = (id: number) => {
        if(window.confirm('Mitarbeiter wirklich löschen?')) {
            fetcher(process.env.REACT_APP_API_URL + '/Person/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Active: 0
                })
            });
            setPersons(persons.filter(person => person.id_Person !== id));
        }
    }

    const row = (person: db_Person, isEven: boolean) => {
        return (
            <div key={person.id_Person} className={(isEven ? 'bg-white ' : 'bg-gray-50 ') + 'px-2 py-3 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6'}>
                <Link to={'./' + person.id_Person} className="mt-1 text-sm text-gray-900">{person.Name}</Link>
                <Link to={'./' + person.id_Person} className="mt-1 text-sm text-gray-900">{getStartDate(person.StartTime)}</Link>
                <Link to={'./' + person.id_Person} className="mt-1 text-sm text-gray-900">{getEndDate(person.EndTime)}</Link>
                <Link to={'./' + person.id_Person} className="mt-1 text-sm text-gray-900">{(person.TargetRevenuePerHour === 0 && person.CostPerHour === 0) ? '-' : person.TargetRevenuePerHour - person.CostPerHour}</Link>
                <Link to={'./' + person.id_Person} className="mt-1 text-sm text-gray-900">{(person.Contractor ? '✅' : '🛑')}</Link>
                <dd className="mt-1 text-sm text-gray-900 space-x-2">
                    <EditButton onClick={(e) => { e.stopPropagation(); navigate(`./${person.id_Person}/edit`)}} />
                    <TrashButton onClick={() => deletePerson(person.id_Person)} />
                </dd>
            </div>
        );
    }

    return (
        <div>
            <div className='flex item-center justify-between my-5'>
                <h1 className='text-2xl font-bold'>Mitarbeiter</h1>
                <Link to='./new'>
                    <CreateButton />
                </Link>
            </div>
            <dl className='shadow-xl'>
                <div className="bg-gray-50 px-2 py-3 sm:grid sm:grid-cols-6 sm:gap-4 sm:px-6 border-y border-gray-200">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dt className="text-sm font-medium text-gray-500">Startdatum</dt>
                    <dt className="text-sm font-medium text-gray-500">Enddatum</dt>
                    <dt className="text-sm font-medium text-gray-500">Bruttogewinn/h</dt>
                    <dt className="text-sm font-medium text-gray-500">Stundenlohn</dt>
                    <dt className="text-sm font-medium text-gray-500">Aktionen</dt>
                </div>
                {persons.map((person, index) => (row(person, index % 2 === 0)))}
            </dl>
        </div>
    );
}

export default PersonsList;
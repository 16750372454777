import { SaveIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CustomButton, BackButton } from '../components/CustomButton';
import CustomDatePicker from '../components/CustomDatePicker';
import CustomInput from '../components/CustomInput';
import { db_Person } from '../models';
import { fetcher } from '../request';
import { eventToState, getStartDateDB, getEndDateDB } from '../helpers';
import CustomCheckbox from '../components/CustomCheckbox';

function PersonEdit() {

    let { personId } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [targetRevenuePerHour, setTargetRevenuePerHour] = useState(0.0);
    const [costPerHour, setCostPerHour] = useState(0.0);
    const [contractor, setContractor] = useState(false);

    useEffect(() => {
        const req = fetcher(process.env.REACT_APP_API_URL + '/Person/' + personId);
        req.then(res => res.json()).then((data: db_Person) => {
            setName(data.Name);
            setStartDate(getStartDateDB(data.StartTime));
            setEndDate(getEndDateDB(data.EndTime, -1));
            setTargetRevenuePerHour(data.TargetRevenuePerHour);
            setCostPerHour(data.CostPerHour);
            setContractor(data.Contractor === 1);
        });
    }, [personId]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        fetcher(process.env.REACT_APP_API_URL + '/Person/' + personId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Name: name,
                StartTime: getStartDateDB(startDate) || null,
                EndTime: getEndDateDB(endDate, 1) || null,
                TargetRevenuePerHour: targetRevenuePerHour || null,
                CostPerHour: costPerHour || null,
                Contractor: contractor ? 1 : 0,
            })
        })
        .then(res => {
            if (res.status === 200) {
                navigate(`../${personId}`);
            }
        });
    }

    return (
        <div>
            <BackButton state = { state } navigate = { navigate } />
            <h1 className='text-2xl font-bold my-5'>Mitarbeiter bearbeiten</h1>
            <CustomInput id='name' label='Name' type='text' value={name} onChange={eventToState(setName)} />
            <CustomDatePicker id='start-date' type='date' label='Startdatum' value={startDate} onChange={eventToState(setStartDate)} />
            <CustomDatePicker id='end-date' type='date' label='Enddatum' value={endDate} onChange={eventToState(setEndDate)} />
            <CustomInput id='hours' label='Zielumsatz pro Stunde' type='number' value={targetRevenuePerHour} onChange={eventToState(setTargetRevenuePerHour)} />
            <CustomInput id='cost' label='Kosten pro Stunde' type='number' value={costPerHour} onChange={eventToState(setCostPerHour)} />
            <CustomCheckbox id='contractor' label='Arbeitet im Stundenlohn' checked={contractor} onChange={(e) => setContractor(e.target.checked)} />
            <CustomButton className='mt-6' Icon={SaveIcon} onClick={handleSubmit}>Speichern</CustomButton>
        </div>
    );
}

export default PersonEdit;
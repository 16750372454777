
import { toast } from 'react-toastify';

const handleResponse = (res: Response) =>
{
  if (res.status === 200) 
    {
      return true;
    }
    else
    {
        let utf8decoder = new TextDecoder();
        if(res.body == null)
        {
            toast.error('Request failed: \r\n' + res.statusText);
            return false;
        }
        else
        {
            res.body.getReader().read().then((data) => {
                let body = utf8decoder.decode(data.value);
                toast.error('Request failed: \r\n' + body);
            });
        }
        return false;
    }
}

function updateOptions(options: any) {
  const update = { ...options };
  if (localStorage.token)
  {
    update.headers = {
      ...update.headers,
      Authorization: `Bearer ${localStorage.token}`,
    };
  }
  return update;
}
  
async function fetcher(url: string, options: any = null, alertIfError: boolean = true)
{
  let response = await fetch(url, updateOptions(options)).catch((error) => {
    toast.error('Request failed: \r\n' + error);
  });
  if(response != null)
  {
    if(response.status === 403)
    {
        localStorage.removeItem('token');
        window.location.replace('/Login');
        toast.info('You have been signed out due to inactivity. Please sign in again.');
    }
    else if(alertIfError)
    {
      handleResponse(response);
    }
    if(response.status === 200)
    {
      //update token
      let res_json = await response.headers.get('Cache-Control');
      if(res_json)
      {
        localStorage.setItem('token', res_json);
      }
    }
  }
  
  return response == null ? new Response() : response;
}

export { handleResponse, fetcher };

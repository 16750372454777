import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useEffect } from 'react';
import { fetcher } from '../request';
import { db_Global } from '../models';

type Props = {
    isCollapsed: boolean;
    setIsCollapsed: (isCollapsed: boolean) => void;
}

function Hamburger({ isCollapsed, setIsCollapsed }: Props) {
  
  useEffect(() => {
    //default menu position
    const req = fetcher(process.env.REACT_APP_API_URL + '/Globals');
    req.then(res => res.json())
    .then(settings => 
    {
      let menuCollapsedBool = false;
      const menuCollapsed = (settings as db_Global[]).filter(r => r.Name === 'Menu_geschlossen');
      if(menuCollapsed && menuCollapsed.length > 0 && menuCollapsed[0].Value === '1'){
        menuCollapsedBool = true;
      }
      setIsCollapsed(menuCollapsedBool);
    });
  }, [setIsCollapsed]);

  function handleShrink() {
    setIsCollapsed(!isCollapsed);
  }
  
  return (
    <button className="absolute right-[-5px] top-[40px] h-8 w-8 p-0 bg-transparent rounded-full border-2 border-transparent hover:border-white" onClick={handleShrink}>
      {isCollapsed ? 
      <MenuIcon className='rounded-full' /> : 
      <XIcon className='rounded-full' />}
    </button>
  );
}

export default Hamburger;


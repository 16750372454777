import React, { ComponentType, ComponentProps, ReactNode } from 'react';
import { ChevronLeftIcon, PencilIcon, PlusIcon, SaveIcon, TrashIcon } from '@heroicons/react/outline';
import { NavigateFunction, NavigateOptions } from 'react-router-dom';

type Props = {
    Icon: ComponentType<ComponentProps<'svg'>>;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    children: ReactNode;
    className?: string;
    textClassName?: string;
    imageClassName?: string;
}

function CustomButton({ Icon, onClick, children, className, textClassName, imageClassName }: Props) {
    return (
        <div className={(onClick ? 'cursor-pointer ' : '') + (className === undefined ? '' : className) + ' bg-blue-100 text-blue-900 hover:bg-blue-200 rounded p-1 pr-2 inline-flex flex-row'} onClick={onClick}>
            <div style={{ 'marginTop': '-0.05rem' }} >
                <Icon className={(imageClassName === undefined ? '' : imageClassName) + ' h-5'} />
            </div>
            <span className={(textClassName === undefined ? '' : textClassName) + ' ml-1 text-sm font-semibold select-none'}>{children}</span>
        </div>
    )
}

type PropsConfigured = {
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
    textClassName?: string;
    imageClassName?: string;
}

function TrashButton({ onClick, className, textClassName, imageClassName }: PropsConfigured) {
    return (
        <CustomButton Icon={TrashIcon} onClick={onClick} className={className} textClassName={textClassName} imageClassName={imageClassName + ' text-red-600'}>
            Löschen
        </CustomButton>
    )
}

function EditButton({ onClick, className, textClassName, imageClassName }: PropsConfigured) {
    return (
        <CustomButton Icon={PencilIcon} onClick={onClick} className={className} textClassName={textClassName} imageClassName={imageClassName + ' text-yellow-600'}>
            Bearbeiten
        </CustomButton>
    )
}

function SaveButton({ onClick, className, textClassName, imageClassName }: PropsConfigured) {
    return (
        <CustomButton Icon={SaveIcon} onClick={onClick} className={className} textClassName={textClassName} imageClassName={imageClassName}>
            Speichern
        </CustomButton>
    )
}


function CreateButton({ onClick, className, textClassName, imageClassName }: PropsConfigured) {
    return (
        <CustomButton Icon={PlusIcon} onClick={onClick} className={className} textClassName={textClassName} imageClassName={imageClassName + ' text-green-600'}>
            Erstellen
        </CustomButton>
    )
}

type BackProps = {
    navigate?: NavigateFunction
    state?: NavigateOptions | unknown;
    className?: string;
    textClassName?: string;
    imageClassName?: string;
}

function BackButton({ navigate, state, className, textClassName, imageClassName }: BackProps) {
    return (
        <CustomButton Icon={ChevronLeftIcon} className={className} textClassName={textClassName} imageClassName={imageClassName} onClick={() =>
        {
            if (state !== undefined && navigate !== undefined) {
                if (state !== null && (typeof state === 'object') && ('fromSpecificPage' in state)) 
                {       
                    navigate(-1);
                } 
                else 
                {
                    let new_loc = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname;
                    new_loc += '/..';
                    navigate(new_loc);
                }
            }
            else
            {
                window.history.back();
            }
        }}>
            Zurück
        </CustomButton>
    )
}

function AddButton({ onClick, className, textClassName, imageClassName }: PropsConfigured) {
    return (
        <CustomButton Icon={PlusIcon} onClick={onClick} className={className} textClassName={textClassName} imageClassName={imageClassName}>
            Hinzufügen
        </CustomButton>
    )
}

export { CustomButton, TrashButton, EditButton, SaveButton, CreateButton, BackButton, AddButton };
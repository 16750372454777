import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';
import { viewModes } from '../models';
import { fetcher } from '../request';
import store from '../redux/store';
import { loadSites } from '../redux/sites/sitesSlice';
import { loadPersons } from '../redux/persons/personsSlice';

type Props = {
    mode: viewModes;
    resourceId: number;
    priority: number;
    children?: ReactNode;
}

function PriorityButtons({ mode, resourceId, priority, children }: Props) {

    const priotizeResource = (e: any, target: string) => {
        e.preventDefault();
        let newPriority = target === "up" ? 1 : -1;
        if(priority === newPriority) {
            newPriority = 0;
        }
        fetcher(process.env.REACT_APP_API_URL + (mode === viewModes.Site ? '/Site/' : '/Person/') + resourceId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Priority: newPriority
            })
        }).then(res => {
            if (res.status === 200) {
                if(mode === viewModes.Site) {
                    store.dispatch(loadSites);
                }
                else if(mode === viewModes.Person) {
                    store.dispatch(loadPersons);
                }
                else {
                    console.log("Mode not supported");
                }
            }
        });
    }
  
    return (
        <div className='py-1'>
            <div onClick={(e) => priotizeResource(e, "up")} className={`h-5 w-5 rounded-full border-2 border-transparent hover:border-white cursor-pointer ${priority === 1 ? 'bg-white' : ''}`}>
               <ArrowSmUpIcon>{children}</ArrowSmUpIcon>
            </div>
            <div onClick={(e) => priotizeResource(e, "down")} className={`h-5 w-5 rounded-full border-2 border-transparent hover:border-white cursor-pointer ${priority === -1 ? 'bg-white' : ''}`}>
                <ArrowSmDownIcon>{children}</ArrowSmDownIcon>
            </div>
        </div>
      
    );
}

export default PriorityButtons;


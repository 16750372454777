import moment from 'moment';
import { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEndDateDB, getStartDateDB } from '../helpers';
import { db_Availability, viewModes } from '../models';
import { activePersonSiteIdSet, addPersonSite } from '../redux/personSites/personSitesSlice';
import { selectSites } from '../redux/sites/sitesSlice';
import store from '../redux/store';
import { fetcher } from '../request';

type RowClickLayerProps = {
    gridRow: string,
    gridColumn: string,
    startDate: moment.Moment,
    timeFrame: number,
    recourceId: number,
    mode: viewModes
}

function RowClickLayer({ gridColumn, gridRow, startDate, timeFrame, recourceId, mode }: RowClickLayerProps) {

    const divRef = useRef<HTMLDivElement>(null);
    const [cellWidth, setCellWidth] = useState(0);
    const sites = useSelector(selectSites);

    const onClick = async (e: React.MouseEvent<HTMLDivElement>) => {
        if (divRef.current) 
        {
            e.preventDefault();
            e.stopPropagation();
            if(e.detail === 1)
            {
                store.dispatch(activePersonSiteIdSet(-1));
            }
            if(e.detail === 2)
            {

                const { clientX } = e;
                const { left } = divRef.current.getBoundingClientRect();
                const x = clientX - left;
    
                const startCol = Math.floor(x / cellWidth / 2) * 2;
                const colSpan = Math.max(2, Math.ceil(200 / cellWidth / 2) * 2);
                const date = startDate.clone().add(startCol * 12, 'hours');
    
                const newPersonSite = {
                    StartTime: date.format('YYYY-MM-DD HH:mm:ss'),
                    EndTime: date.clone().add(colSpan * 12, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                    id_Person: 0,
                    id_Site: 0,
                    Active: 1,
                    New: true
                };
    
                if (mode === viewModes.Person) {
                    newPersonSite.id_Person = recourceId;
                    newPersonSite.id_Site = sites[0].id_Site;
                } else {
                    newPersonSite.id_Site = recourceId;
                    const jsCompatibleDate = (date: string) => {
                        return date.substring(0, 10) + 'T' + date.substring(11, 19) + 'Z';
                    }
                    const res = await fetcher(`${process.env.REACT_APP_API_URL}/Availability?Start=${jsCompatibleDate(getStartDateDB(newPersonSite.StartTime))}&End=${jsCompatibleDate(getEndDateDB(newPersonSite.EndTime, 0))}`);
                    const data: db_Availability[] = await res.json();
                    newPersonSite.id_Person = data[0].id_Person;
                }
    
                store.dispatch(addPersonSite(newPersonSite));
            }
        }
    }

    useLayoutEffect(() => {
        if (divRef.current) {
            const width = divRef.current.getBoundingClientRect().width;
            setCellWidth(width / (timeFrame * 2));
        }
    }, [gridColumn, gridRow, timeFrame])

    return (
        <div
            ref={divRef}
            onClick={onClick}
            style={{
                gridColumn: gridColumn,
                gridRow: gridRow,
                zIndex: 0,
                cursor: 'pointer'
            }}
            className='border'
        ></div>
    );
}

export default RowClickLayer;
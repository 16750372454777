import { configureStore } from '@reduxjs/toolkit';
import sitesReducer from './sites/sitesSlice';
import personsReducer from './persons/personsSlice';
import personSitesReducer from './personSites/personSitesSlice';
import globalsReducer from './globals/globalsSlice';
import planningPageReducer from './planningPage/planningPageSlice';
import absencesReducer from './absences/absencesSlice';
import holidaysReducer from './holidays/holidaysSlice';

const store = configureStore({
    reducer: {
        sites: sitesReducer,
        persons: personsReducer,
        personSites: personSitesReducer,
        globals: globalsReducer,
        planningPage: planningPageReducer,
        absences: absencesReducer,
        holidays: holidaysReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
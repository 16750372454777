import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { db_Holiday } from '../../models';
import { fetcher } from '../../request';
import type { RootState, AppDispatch } from '../store';

type HolidaysState = {
  holidaysByPersonId: Record<string, db_Holiday[]>
};

const initialState: HolidaysState = {
  holidaysByPersonId: {}
};

export const holidaysSlice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    holidayAdded: (state, action: PayloadAction<db_Holiday>) => {
      if (!state.holidaysByPersonId[action.payload.id_Person]) {
        state.holidaysByPersonId[action.payload.id_Person] = [action.payload];
      } else {
        state.holidaysByPersonId[action.payload.id_Person].push(action.payload);
      }
    },
    holidayDeleted: (state, action: PayloadAction<db_Holiday>) => {
      if (state.holidaysByPersonId[action.payload.id_Person]) {
        state.holidaysByPersonId[action.payload.id_Person] = state.holidaysByPersonId[action.payload.id_Person].filter(holiday => holiday.id_Holidays !== action.payload.id_Holidays);
      }
    },
    holidayUpdated: (state, action: PayloadAction<db_Holiday>) => {
      if (state.holidaysByPersonId[action.payload.id_Person]) {
        const index = state.holidaysByPersonId[action.payload.id_Person].findIndex((holiday) => holiday.id_Holidays === action.payload.id_Holidays);
        if (index !== -1) {
          state.holidaysByPersonId[action.payload.id_Person][index] = action.payload;
        }
      }
    },
    holidaysLoaded: (state, action: PayloadAction<db_Holiday[]>) => {
      state.holidaysByPersonId = action.payload.reduce((holidaysByPersonId, holiday) => {
        if (!holidaysByPersonId[holiday.id_Person]) {
          holidaysByPersonId[holiday.id_Person] = [holiday];
        } else {
          holidaysByPersonId[holiday.id_Person].push(holiday);
        }
        return holidaysByPersonId;
      }, {} as HolidaysState['holidaysByPersonId']);
    },
  },
});

export const { holidayAdded, holidayDeleted, holidayUpdated, holidaysLoaded } = holidaysSlice.actions;

export async function loadHolidays(dispatch: AppDispatch) {
  const response = await fetcher(process.env.REACT_APP_API_URL + '/Holidays');
  const holidays = await response.json();
  dispatch(holidaysLoaded(holidays));
}

export function addHoliday(holiday: db_Holiday) {
  return async (dispatch: AppDispatch) => {
    const response = await fetcher(process.env.REACT_APP_API_URL + '/Holidays', {
      method: 'POST',
      body: JSON.stringify(holiday),
    });
    const addedHoliday = await response.json();
    dispatch(holidayAdded(addedHoliday));
  };
}

export async function deleteHoliday(dispatch: AppDispatch, holiday: db_Holiday) {
  await fetcher(process.env.REACT_APP_API_URL + '/Holidays', {
    method: 'PUT',
    body: JSON.stringify({
      Active: 0
    }),
  });
  dispatch(holidayDeleted(holiday));
}

export function updateHoliday(holiday: db_Holiday) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const newHoliday = {
      ...holiday,
      id_Holidays: undefined,
    };
    const response = await fetcher(process.env.REACT_APP_API_URL + '/Holidays/' + holiday.id_Holidays, {
      method: 'PUT',
      body: JSON.stringify(newHoliday),
    });
    await response.json();
    dispatch(holidayUpdated(holiday));
  };
}

export const selectHolidaysIndex = (state: RootState) => state.holidays.holidaysByPersonId;
export const selectHolidaysByPersonId = (personId: string) => (state: RootState) => state.holidays.holidaysByPersonId[personId];

export default holidaysSlice.reducer;
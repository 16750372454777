import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

type Props = {
    children: React.ReactElement
}

const AuthGaurd = ({ children }: Props) => {
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login')
        }
    }, [navigate, location.pathname])
    return children
}

export default AuthGaurd

function About() 
{
  
  return(
    <div>
      <div className='flex item-center justify-between my-5'>
          <h1 className='text-2xl font-bold'>Impressum</h1>
      </div>
      <dl className='shadow-xl p-10'>
        <h3 className='text-center text-xl font-bold mb-6'>MSAG Workmanager</h3>
          <dd>Die "MSAG Workmanager" Webapplikation wurde durch Jelle Schutter und Pascal Berger im Auftrag der Meier-Schmocker AG produziert. 
            Das Projekt wurde von 07.2022 bis 02.2023 durchgeführt.
            Es dient der Planung, Dokumentation und Übersicht von Aufträgen, Mitarbeitern und Arbeitseinteilungen.
            Für die Entwicklung wurde React, Typescript, Javascript und MariaDB verwendet. 3rd party libraries: date-fns, jsonwebtoken, mysql, moment, chart.js, crypto-js, @fontsource/roboto, @heroicons/react, @reduxjs/toolkit.
          </dd>
          <dd>Wir bedanken uns für den Auftrag und wünschen viel Spass mit der Applikation.</dd>
          <dd className="mt-3">Dieses Projekt wurde im Auftrag entwickelt. Die Verantwortung für Dokumentation und Handhabung der Software, sowie Verwaltung und Sicherung der Daten liegt beim Kunden. Jegliche Haftung wird ausgeschlossen.</dd>
          <dd className="mt-3">Nach Projektabschluss fallen lediglich Kosten für Hosting an. Anpassungen und Erweiterungen werden nach Möglichkeit auf auf Offerte gerne bearbeitet.</dd>
          <div className='flex flex-row justify-center mt-5'>
            <div>
              <dd className='font-semibold'>Kontakt</dd>
              <button onClick={ (e) => window.location.href ="mailto:pascal.berger21@gmail.com" } className="text-blue-500 hover:text-blue-600">Pascal Berger</button>
            </div>
            <div className="ml-10">
              <dd className='font-semibold'>Source Code (auf Anfrage)</dd>
              <button onClick={ (e) => window.location.href ="https://github.com/wojek" } className="text-blue-500 hover:text-blue-600">GitHub</button>
            </div>
           <div className="ml-10">
              <dd className='font-semibold'>Backend/DB Hosting</dd>
              <button onClick={ (e) => window.location.href ="https://myh.godaddy.com/de-CH#/hosting/vps4/servers/0341ab36-3cba-11ed-827f-008cfaff3e74/dashboard" } className="text-blue-500 hover:text-blue-600">GoDaddy</button>
           </div>
           <div className="ml-10">
              <dd className='font-semibold'>Frontend Hosting</dd>
              <button onClick={ (e) => window.location.href ="https://www.cloudflare.com/" } className="text-blue-500 hover:text-blue-600">Cloudflare</button>
           </div>
          </div>
        <h3 className='text-center text-xl font-bold mb-6 mt-24'>Team Wojek</h3>
        <div className='flex flex-row justify-center'>
          <div className='mr-24'>
            <div className='flex flex-row'>
              <img className="rounded-full w-48 mr-5" src={require("./pascal.jpeg")} alt={"Pascal Berger"} />
              <div className='flex flex-col justify-center'>
                <dt className='font-bold'>Pascal Berger</dt>
                <dd>Projektleiter, Webentwickler</dd>
                <dd>pascal.berger21@gmail.com</dd>
                <dd>076 749 02 29</dd>
                <button onClick={ (e) => window.location.href ="https://github.com/phyti1" } className="text-blue-500 hover:text-blue-600 text-left">GitHub</button>
                <button onClick={ (e) => window.location.href ="https://www.linkedin.com/in/pascal-berger-135406157/" } className="text-blue-500 hover:text-blue-600 text-left">LinkedIn</button>
              </div>
            </div>
          </div>
          <div>
            <div className='flex flex-row'>
              <img className="rounded-full w-48 mr-5" src={require("./jelle.jpeg")} alt={"Pascal Berger"} />
              <div className='flex flex-col justify-center'>
                <dt className='font-bold'>Jelle Schutter</dt>
                <dd>Webentwickler</dd>
                <dd>jelle@schutter.xyz</dd>
                <dd>.</dd>
                <button onClick={ (e) => window.location.href ="https://github.com/jelleschutter" } className="text-blue-500 hover:text-blue-600 text-left">GitHub</button>
                <button onClick={ (e) => window.location.href ="https://www.linkedin.com/in/jelleschutter/" } className="text-blue-500 hover:text-blue-600 text-left">LinkedIn</button>
              </div>
            </div>
          </div>
        </div>
      </dl>
  </div>
  );
}

export default About;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStartDateDB, getEndDateDB } from '../../helpers';
import { db_Site, v_PersonSite, NewPersonSite } from '../../models';
import { fetcher } from '../../request';
import { personUpdated, selectPersonById } from '../persons/personsSlice';
import { selectSiteById, updateSiteProgress } from '../sites/sitesSlice';
import type { RootState, AppDispatch } from '../store';

type PersonSitesState = {
    personSites: v_PersonSite[],
    personSitesById: Record<number, v_PersonSite>,
    personSitesByPersonId: Record<number, v_PersonSite[]>,
    personSitesBySiteId: Record<number, v_PersonSite[]>,
    activePersonSiteId?: number,
};

const initialState: PersonSitesState = {
    personSites: [],
    personSitesById: {},
    personSitesByPersonId: {},
    personSitesBySiteId: {},
    activePersonSiteId: undefined,
};

export const personSitesSlice = createSlice({
    name: 'personSites',
    initialState,
    reducers: {
        activePersonSiteIdSet: (state, action: PayloadAction<number>) => {
            state.activePersonSiteId = action.payload;
        },
        personSiteAdded: (state, action: PayloadAction<v_PersonSite>) => {
            state.personSites.push(action.payload);
            state.personSitesById[action.payload.id_PersonSite] = action.payload;

            if (state.personSitesByPersonId[action.payload.id_Person]) {
                state.personSitesByPersonId[action.payload.id_Person].push(action.payload);
            } else {
                state.personSitesByPersonId[action.payload.id_Person] = [action.payload];
            }

            if (state.personSitesBySiteId[action.payload.id_Site]) {
                state.personSitesBySiteId[action.payload.id_Site].push(action.payload);
            } else {
                state.personSitesBySiteId[action.payload.id_Site] = [action.payload];
            }
        },
        personSiteDeleted: (state, action: PayloadAction<v_PersonSite>) => {
            state.personSites = state.personSites.filter(site => site.id_PersonSite !== action.payload.id_PersonSite);

            state.personSitesBySiteId[action.payload.id_Site] = state.personSitesBySiteId[action.payload.id_Site]
                .filter(personSite => personSite.id_PersonSite !== action.payload.id_PersonSite);

            state.personSitesByPersonId[action.payload.id_Person] = state.personSitesByPersonId[action.payload.id_Person]
                .filter(personSite => personSite.id_PersonSite !== action.payload.id_PersonSite);

            delete state.personSitesById[action.payload.id_PersonSite];
        },
        personSiteUpdated: (state, action: PayloadAction<v_PersonSite>) => {
            const oldPersonSite = state.personSitesById[action.payload.id_PersonSite];

            const mapper = (personSite: v_PersonSite) => personSite.id_PersonSite === action.payload.id_PersonSite ? action.payload : personSite;
            state.personSites = state.personSites.map(mapper);
            
            if (oldPersonSite.id_Site === action.payload.id_Site) {
                state.personSitesBySiteId[action.payload.id_Site] = state.personSitesBySiteId[action.payload.id_Site].map(mapper);
            } else {
                state.personSitesBySiteId[oldPersonSite.id_Site] = state.personSitesBySiteId[oldPersonSite.id_Site]
                    .filter(personSite => personSite.id_PersonSite !== action.payload.id_PersonSite);
                if (state.personSitesBySiteId[action.payload.id_Site]) {
                    state.personSitesBySiteId[action.payload.id_Site].push(action.payload);
                } else {
                    state.personSitesBySiteId[action.payload.id_Site] = [action.payload];
                }
            }

            if (oldPersonSite.id_Person === action.payload.id_Person) {
                state.personSitesByPersonId[action.payload.id_Person] = state.personSitesByPersonId[action.payload.id_Person].map(mapper);
            } else {
                state.personSitesByPersonId[oldPersonSite.id_Person] = state.personSitesByPersonId[oldPersonSite.id_Person]
                    .filter(personSite => personSite.id_PersonSite !== action.payload.id_PersonSite);
                if (state.personSitesByPersonId[action.payload.id_Person]) {
                    state.personSitesByPersonId[action.payload.id_Person].push(action.payload);
                } else {
                    state.personSitesByPersonId[action.payload.id_Person] = [action.payload];
                }
            }

            state.personSitesById[action.payload.id_PersonSite] = action.payload;
        },
        personSitesLoaded: (state, action: PayloadAction<v_PersonSite[]>) => {
            state.personSites = action.payload;
            state.personSitesBySiteId = action.payload.reduce((personSitesBySiteId, personSite) => {
                if (personSitesBySiteId[personSite.id_Site]) {
                    personSitesBySiteId[personSite.id_Site].push(personSite);
                } else {
                    personSitesBySiteId[personSite.id_Site] = [personSite];
                }
                return personSitesBySiteId;
            }, {} as PersonSitesState['personSitesBySiteId']);
            state.personSitesByPersonId = action.payload.reduce((personSitesByPersonId, personSite) => {
                if (personSitesByPersonId[personSite.id_Person]) {
                    personSitesByPersonId[personSite.id_Person].push(personSite);
                } else {
                    personSitesByPersonId[personSite.id_Person] = [personSite];
                }
                return personSitesByPersonId;
            }, {} as PersonSitesState['personSitesByPersonId']);
            state.personSitesById = action.payload.reduce((personSitesById, personSite) => {
                personSitesById[personSite.id_PersonSite] = personSite;
                return personSitesById;
            }, {} as PersonSitesState['personSitesById']);
        },
    },
});

export const { personSiteAdded, personSiteDeleted, personSiteUpdated, personSitesLoaded, activePersonSiteIdSet } = personSitesSlice.actions;

const triggerResourcesUpdate = (dispatch: AppDispatch, getState: () => RootState, personSite: v_PersonSite, oldPersonSite?: v_PersonSite) => {
    // update site progress if Site is active
    const site = selectSiteById(personSite.id_Site)(getState());
    if (site) {
        dispatch(updateSiteProgress(site));
    }
    
    if (oldPersonSite && oldPersonSite.id_Site !== personSite.id_Site) {
        const oldSite = selectSiteById(oldPersonSite.id_Site)(getState());
        if (oldSite) {
            dispatch(updateSiteProgress(oldSite));
        }
    }
    if (oldPersonSite && oldPersonSite.id_Person !== personSite.id_Person) {
        const oldPerson = selectPersonById(oldPersonSite.id_Person)(getState());
        if (oldPerson) {
            dispatch(personUpdated({ ...oldPerson }));
        }
    }
}

export async function loadPersonSites(dispatch: AppDispatch) {
    const response = await fetcher(process.env.REACT_APP_API_URL + '/PersonSite');
    const personSites = await response.json() as v_PersonSite[];
    // default initializer
    personSites.forEach(es => es.RowIndex = -1);
    dispatch(personSitesLoaded(personSites));
}

export function addPersonSite(newPersonSite: NewPersonSite) {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const isNew = !!newPersonSite.New;
        delete newPersonSite.New;
        const res = await fetcher(process.env.REACT_APP_API_URL + '/PersonSite', {
            method: 'POST',
            body: JSON.stringify(newPersonSite),
        });
        const personSite: v_PersonSite = await res.json();
        personSite.New = isNew;
        dispatch(personSiteAdded(personSite));

        dispatch(activePersonSiteIdSet(personSite.id_PersonSite));

        triggerResourcesUpdate(dispatch, getState, personSite);
    }
}

export function deletePersonSite(personSite: v_PersonSite) {
    return async (dispatch: AppDispatch) => {
        await fetcher(process.env.REACT_APP_API_URL + '/PersonSite/' + personSite.id_PersonSite, {
            method: 'PUT',
            body: JSON.stringify({
                Active: 0
            }),
        });
        dispatch(personSiteDeleted(personSite));
        //update site progress
        let site_res = await fetcher(process.env.REACT_APP_API_URL + '/Site/' + personSite.id_Site, {
            method: 'GET',
        });
        let site = await site_res.json() as db_Site;
        dispatch(updateSiteProgress(site));
    }
}

export function updatePersonSite(personSite: v_PersonSite, oldPersonSite: v_PersonSite) {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        delete personSite.New;
        const newPersonSite = {
            ...personSite,
            id_PersonSite: undefined,
            RowIndex: undefined,
        };
        const oldPersonSiteC = {
            ...oldPersonSite,
            id_PersonSite: undefined,
            RowIndex: undefined,
        };
        newPersonSite.StartTime = getStartDateDB(newPersonSite.StartTime);
        newPersonSite.EndTime = getEndDateDB(newPersonSite.EndTime, 0);
        oldPersonSiteC.StartTime = getStartDateDB(oldPersonSiteC.StartTime);
        oldPersonSiteC.EndTime = getEndDateDB(oldPersonSiteC.EndTime, 0);

        await fetcher(process.env.REACT_APP_API_URL + '/PersonSite/' + personSite.id_PersonSite, {
            method: 'PUT',
            body: JSON.stringify(newPersonSite),
        });
        dispatch(personSiteUpdated(personSite));

        if(JSON.stringify(newPersonSite) !== JSON.stringify(oldPersonSiteC)) 
        {
            triggerResourcesUpdate(dispatch, getState, personSite, oldPersonSite);
        }
    }
}

export const selectActivePersonSiteId = (state: RootState) => state.personSites.activePersonSiteId;
export const selectPersonSites = (state: RootState) => state.personSites.personSites;
export const selectPersonSiteById = (id: number) => (state: RootState) => state.personSites.personSitesById[id];
export const selectPersonSiteIndex =  (state: RootState) => state.personSites.personSitesById;
export const selectPersonSitesByPersonIndex = (state: RootState) => state.personSites.personSitesByPersonId;
export const selectPersonSitesBySiteIndex = (state: RootState) => state.personSites.personSitesBySiteId;

export const selectIndexByDateRange = (selectIndex: (state: RootState) => Record<number, v_PersonSite[]>, startDate: Date, endDate: Date) => (state: RootState) => {
    const index = selectIndex(state);
    const indexByDateRange: Record<number, v_PersonSite[]> = {};
    for (const id in index) {
        indexByDateRange[id] = index[id].filter(personSite => {
            const personSiteStartDate = new Date(personSite.StartTime);
            const personSiteEndDate = new Date(personSite.EndTime);
            return (
                (personSiteStartDate >= startDate && personSiteEndDate <= endDate) ||
                (personSiteStartDate <= startDate && personSiteEndDate >= startDate) ||
                (personSiteStartDate <= endDate && personSiteEndDate >= endDate)
            );
        });
    }
    return indexByDateRange;
}

export default personSitesSlice.reducer;
import { CSSProperties } from 'react';
import Event from './Event';

type Props = {
    x: number,
    y: number,
    gridRow: string | number,
    gridColumn: string | number,
    gridColumnSpan: number,
    cellHeight: number,
    cellWidth: number,
    actualColumn: number,
    actualColumnSpan: number,
    props: {
        [key: string]: string | number
    }
}

function getStyles(
    x: number,
    y: number,
    width: number,
    height: number,
): CSSProperties {
    return {
        position: 'absolute',
        top: y,
        left: x,
        width: `${width}px`,
        height: `${height}px`,
    }
}

function EventDragPreview({ x, y, gridRow, gridColumn, gridColumnSpan, actualColumn, actualColumnSpan, cellHeight, cellWidth, props }: Props) {
    const width = cellWidth * actualColumnSpan;
    // const snappedY = y > 0 ? Math.floor((y + cellHeight * 0.5) / cellHeight) * cellHeight : 0;
    // const snappedX = x > 0 ? Math.floor(x / cellWidth) * cellWidth : 0;
    const snappedY = y;
    const snappedX = x;
    props.paddingLeft = snappedX < 0 ? -snappedX : 0;
    return <div className='p-1' style={getStyles(snappedX, snappedY, width, cellHeight)}>
        <Event {...props} />
    </div>
}

export default EventDragPreview;
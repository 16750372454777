import { useState } from 'react';
import { fetcher } from '../request';
import { getStartDateDB, getEndDateDB } from '../helpers';

type Props = {
    id?: string,
    label?: string,
    type?: string,
    value?: string,
    placeholder?: string,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    url?: string,
    endDate?: boolean,
    className?: string
}

function CustomDatePicker({ id, label, type, placeholder, value, onChange, url, endDate = false, className }: Props) {
    if(value != null && value !== '' && value.length !== 10)
    {
        value = getStartDateDB(value).slice(0, 10);
    }
    if(value != null && endDate)
    {
        value = getEndDateDB(value, -1).slice(0, 10);
    }
    let myOnChange = null;
    let [myValue, setMyValue] = useState<string | undefined>(value);

    if(url != null) 
    {
        myOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if(onChange != null)
            {
                onChange(e);
            }
            let new_date = e.target.value;
            if(endDate)
            {
                new_date = getEndDateDB(new_date, 1);
            }
            else
            {
                new_date = getStartDateDB(new_date);
            }
            let match = url.match(/(.*\/)(\D+)$/);
            if(match != null && match.length === 3)
            {
                let property = match[2];
                fetcher(process.env.REACT_APP_API_URL + match[1], {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: `{"${property}": "${new_date}"}`,
                });
            }
            else{
                console.error('Invalid url: ' + url);
            }
            setMyValue(e.target.value);
        }
    }
    else
    {
        myOnChange = onChange;
    }

    return (
        <div className={className}>
            <label htmlFor={id} className='block text-sm font-medium text-gray-500 select-none'>{label}</label>
            <input id={id} type='date' placeholder={placeholder} value={url != null ? myValue : value} onChange={myOnChange} 
                className='block mt-1 appearance-none border-2 border-gray-200 rounded-lg py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 mb-3' />
        </div>
    )
}

export default CustomDatePicker;
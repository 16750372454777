import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { db_Person } from '../../models';
import { fetcher } from '../../request';
import type { RootState, AppDispatch } from '../store';

type PersonsState = {
  persons: db_Person[],
  personsById: Record<string, db_Person>,
};

const initialState: PersonsState = {
  persons: [],
  personsById: {},
};

export const personsSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: {
    personAdded: (state, action: PayloadAction<db_Person>) => {
        state.persons.push(action.payload);
        state.personsById[action.payload.id_Person] = action.payload;
    },
    personDeleted: (state, action: PayloadAction<db_Person>) => {
        state.persons = state.persons.filter(person => person.id_Person !== action.payload.id_Person);
        delete state.personsById[action.payload.id_Person];
    },
    personUpdated: (state, action: PayloadAction<db_Person>) => {
        const index = state.persons.findIndex((person) => person.id_Person === action.payload.id_Person);
        if (index !== -1) {
            state.persons[index] = action.payload;
            state.personsById[action.payload.id_Person] = action.payload;
        }
    },
    personsLoaded: (state, action: PayloadAction<db_Person[]>) => {
        state.persons = action.payload;
        state.personsById = action.payload.reduce((personsById, person) => {
            personsById[person.id_Person] = person;
            return personsById;
        }, {} as PersonsState['personsById']);
    },
  },
});

export const { personAdded, personDeleted, personUpdated, personsLoaded } = personsSlice.actions;

export async function loadPersons(dispatch: AppDispatch) {
    const response = await fetcher(process.env.REACT_APP_API_URL + '/Person');
    const persons = await response.json();
    dispatch(personsLoaded(persons));
}

export async function addPerson(dispatch: AppDispatch, person: db_Person) {
    const response = await fetcher(process.env.REACT_APP_API_URL + '/Person', {
        method: 'POST',
        body: JSON.stringify(person),
    });
    const addedPerson = await response.json();
    dispatch(personAdded(addedPerson));
}

export async function deletePerson(dispatch: AppDispatch, person: db_Person) {
    await fetcher(process.env.REACT_APP_API_URL + '/Person/' + person.id_Person, {
        method: 'PUT',
        body: JSON.stringify({
            Active: 0
        }),
    });
    dispatch(personDeleted(person));
}

export async function updatePerson(person: db_Person) {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const newPerson = {
            ...person,
            id_Person: undefined,
        };
        const response = await fetcher(process.env.REACT_APP_API_URL + '/Person/' + person.id_Person, {
            method: 'PUT',
            body: JSON.stringify(newPerson),
        });
        const updatedPerson = await response.json();
        dispatch(personUpdated(updatedPerson));
    };
}

export const selectPersons = (state: RootState) => state.persons.persons;
export const selectPersonById = (id: number) => (state: RootState) => state.persons.personsById[id];
export const selectPersonIndex = (state: RootState) => state.persons.personsById;

export default personsSlice.reducer;
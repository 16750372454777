import moment from 'moment';
import 'moment/locale/de';
import { useEffect, useRef } from 'react';
import { db_Person, v_PersonSite, db_Site, viewModes } from '../models';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CustomDragLayer from './CustomDragLayer';
import DraggableEvent from './DraggableEvent';
import { CustomButton } from '../components/CustomButton';
import { CalendarIcon, PlusCircleIcon } from '@heroicons/react/outline';
import CustomDatePicker from '../components/CustomDatePicker';
import store, { RootState } from '../redux/store';
import { activePersonSiteIdSet, loadPersonSites, selectActivePersonSiteId, selectIndexByDateRange, updatePersonSite } from '../redux/personSites/personSitesSlice';
import { loadSites } from '../redux/sites/sitesSlice';
import { loadPersons } from '../redux/persons/personsSlice';
import { useSelector } from 'react-redux';
import { getStartDateDB, getEndDateDB, getKWFromDate, getShortName } from '../helpers';
import { selectGlobals } from '../redux/globals/globalsSlice';
import RowClickLayer from './RowClickLayer';
import { Link, useNavigate } from 'react-router-dom';
import { selectPlanningPage, startDateChanged, timeFrameChanged } from '../redux/planningPage/planningPageSlice';
import { loadAbsences, selectAbsencesWeekdayIndex } from '../redux/absences/absencesSlice';
import { loadHolidays, selectHolidaysIndex } from '../redux/holidays/holidaysSlice';
import PriorityButtons from '../components/PriorityButtons';

//monday 0, sunday 6, german day names
moment.locale('de');

function Planner<T1 extends db_Site | db_Person, T2 extends db_Site | db_Person>(
{ 
    mode,
    resourcesSelector, 
    eventsSelector, 
    indexSelector 
}: 
{
    mode: viewModes,
    resourcesSelector: (state: RootState) => T1[],
    eventsSelector: (state: RootState) => Record<number, v_PersonSite[]>,
    indexSelector: (state: RootState) => Record<number, T2>,
})
{
    if(mode !== viewModes.Site && mode !== viewModes.Person)
    {
        throw new Error("Invalid mode");
    }
    const navigate = useNavigate();
    // // initial day is monday of current week
    // const [startDate, setStartDate] = useState(now.weekday(0));
    // const [timeFrame, setTimeFrame] = useState(7);
    const { startDate, timeFrame } = useSelector(selectPlanningPage);

    const setStartDate = (date: moment.Moment) => {
        store.dispatch(startDateChanged(date.toISOString()));
    }

    const gridContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        store.dispatch(loadSites);
        store.dispatch(loadPersons);
        store.dispatch(loadPersonSites);
        store.dispatch(loadAbsences);
        store.dispatch(loadHolidays);
    }, []);
    
    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent): any => {
            const key = e.key;

            if (key === 'ArrowLeft') {
                setStartDate(moment(startDate).subtract(7, 'days'));
            }
            else if (key === 'ArrowRight') {
                setStartDate(moment(startDate).add(7, 'days'));
            }
            else if (key === 'ArrowUp') {
                store.dispatch(timeFrameChanged(timeFrame + 1));
            }
            else if (key === 'ArrowDown') {
                if (timeFrame > 2) {
                    store.dispatch(timeFrameChanged(timeFrame - 1));
                }
            }
            else if (key === 'Escape' || key === 'Enter') {
                store.dispatch(activePersonSiteIdSet(-1));
            } else {
                return;
            }
            e.preventDefault();
        };

        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        }
    }, [startDate, timeFrame]);

    let resources = useSelector(resourcesSelector);
    const index = useSelector(indexSelector);
    const events = useSelector(selectIndexByDateRange(eventsSelector, startDate.toDate(), startDate.clone().add(timeFrame, 'days').toDate()));
    const activePersonSiteId = useSelector(selectActivePersonSiteId);
    const absencesIndex = useSelector(selectAbsencesWeekdayIndex);
    const holidaysIndex = useSelector(selectHolidaysIndex);

    const eventToGridRowIndex: Record<string, number> = {};
    const gridRowToResourceIndex: Record<number, number> = {};

    const moveResizeCell = (personSite: v_PersonSite, startColDiff: number, endColDiff: number, rowDiff: number, row: number) => {
        const newPersonSite = { ...personSite };
        const oldPersonSite = { ...personSite };

        const oldStartTime = moment(personSite.StartTime);
        const oldEndTime = moment(personSite.EndTime);
        const newStartTime = oldStartTime.clone();
        newStartTime.set('hour', Math.round(newStartTime.get('hour') / 12) * 12);
        newStartTime.add(startColDiff * 12, 'hours');
        const newEndTime = oldEndTime.clone()
        newEndTime.set('hour', Math.round(newEndTime.get('hour') / 12) * 12);
        newEndTime.add(endColDiff * 12, 'hours');

        if (newStartTime.isBefore(newEndTime) && startColDiff !== 0) {
            newPersonSite.StartTime = getStartDateDB(newStartTime);
        } else {
            newPersonSite.StartTime = getStartDateDB(oldStartTime);
        }
        if (newEndTime.isAfter(newStartTime) && endColDiff !== 0) {
            newPersonSite.EndTime = getEndDateDB(newEndTime, 0);
        } else {
            newPersonSite.EndTime = getEndDateDB(oldEndTime, 0);
        }
        if(rowDiff !== 0){
            const newRowID = gridRowToResourceIndex[row + rowDiff];
            if (mode === viewModes.Site) {
                newPersonSite.id_Site = newRowID;
            } else {
                newPersonSite.id_Person = newRowID;
            }
        }
        console.log(newPersonSite, gridRowToResourceIndex, rowDiff, row);
        return store.dispatch(updatePersonSite(newPersonSite, oldPersonSite));
    }

    const inRangeIndex = (item: T1) => {
        const id = ('id_Site' in item ? item.id_Site : item.id_Person);
        const startDateDiff = moment(item.StartTime).startOf('day').diff(startDate, 'days');
        const endDateDiff = moment(item.EndTime).startOf('day').diff(startDate, 'days');
        let inRange = (startDateDiff <= 0 && (endDateDiff >= timeFrame || item.EndTime === null)) ||
            (startDateDiff >= 0 && startDateDiff < timeFrame && item.EndTime === null) ||
            (endDateDiff > 0 && endDateDiff <= timeFrame) ||
            (startDateDiff >= 0 && startDateDiff < timeFrame) ||
            (startDateDiff >= 0 && endDateDiff <= timeFrame) ||
            (item.StartTime === null && item.EndTime === null) ||
            (typeof events[id] !== 'undefined' && events[id].length > 0) ? 1 : 0;
        if(inRange !== 0){
            inRange = id;
        }
        return inRange;
    }

    const siteClicked = (e: any, resource: db_Site | db_Person) => {
        e.preventDefault();
        store.dispatch(activePersonSiteIdSet(-1));
        //if double click
        if(e.detail === 2)
        {
            //check if resource is Site
            if ('id_Site' in resource)
            {
                let parent_site = '';
                if(resource.EndTime == null || moment.utc(resource.EndTime) > moment())
                {
                    parent_site = 'sites-open';
                }
                else
                {
                    parent_site = 'sites-closed';
                }
                navigate(`/${parent_site}/${resource.id_Site}`, { state: { fromSpecificPage: true } });
            }
            else if('id_Person' in resource)
            {
                navigate(`/persons/${resource.id_Person}`, { state: { fromSpecificPage: true } });
            }
        }
    }

    let deactivatedResources = 0;
    const settings = useSelector(selectGlobals);
    const onlyActive = settings.filter(r => r.Name === 'NurAktiveRessourcen');
    if(onlyActive && onlyActive.length > 0 && onlyActive[0].Value === '1'){
        deactivatedResources = 1;
    }

    resources = resources.slice().filter(r => deactivatedResources ? inRangeIndex(r) !== 0 ? true : false : true );
    resources.sort((a, b) => {
        if (a.Priority === b.Priority) 
        {
            // nested sorting
            return a.StartTime < b.StartTime ? -1 : 1;
        }
        return b.Priority - a.Priority;
    });

    let currentRowIndex = 2;
    const { elements: resourcesHtml, index: numResources } = resources
        .reduce((prev: { elements: (JSX.Element|null)[], index: number }, r) => {
            const id = ('id_Site' in r ? r.id_Site : r.id_Person);

            let rowHeight = 0;
            let personSitesHtml: JSX.Element[] = [];

            if (typeof events[id] !== 'undefined') {
                let personSites = [...events[id]].filter(es => {
                    const startDateDiff = Math.round(moment(es.StartTime).diff(startDate, 'hours') / 12);
                    const endDateDiff = Math.round(moment(es.EndTime).diff(startDate, 'hours') / 12);
                    const timeFrameInHalfDays = timeFrame * 2;
                    const inRange = (startDateDiff <= 0 && endDateDiff >= timeFrameInHalfDays) ||
                        (endDateDiff > 0 && endDateDiff <= timeFrameInHalfDays) ||
                        (startDateDiff >= 0 && startDateDiff < timeFrameInHalfDays) ||
                        (startDateDiff >= 0 && endDateDiff <= timeFrameInHalfDays);
                    return inRange;
                });
                if (personSites.length > 0) {
                    personSitesHtml = personSites.map((es, i) => {
                        const startDateDiff = Math.round(moment(es.StartTime).diff(startDate, 'hours') / 12);
                        const endDateDiff = Math.round(moment(es.EndTime).diff(startDate, 'hours') / 12);
                        let classes = 'p-0.5';
                        if (startDateDiff < 0) {
                            classes += ' pl-0';
                        }
                        if (endDateDiff >= timeFrame * 2) {
                            classes += ' pr-0';
                        }

                        const actualColumn = (startDateDiff + 2);
                        const gridColumn = Math.max(2, actualColumn);
                        const gridColumnSpan = (Math.min(timeFrame * 2, endDateDiff) - Math.max(0, startDateDiff));
                        const actualColumnSpan = (endDateDiff - startDateDiff);
                        const eventProps: {[key: string]: string} = {};
                        const searchId = (mode === viewModes.Site ? es.id_Person : es.id_Site);
                        if (typeof index[searchId] === 'undefined') {
                            eventProps.name = 'Unknown';
                        } else {
                            eventProps.name = getShortName(index[searchId].Name);
                        }
                        let gridRow = currentRowIndex;

                        if (eventToGridRowIndex[es.id_Person + '-' + es.id_Site] === undefined)
                        {
                            eventToGridRowIndex[es.id_Person + '-' + es.id_Site] = currentRowIndex;
                            //eventProps.name = es.id_Person + '-' + es.id_Site;
                            rowHeight++;
                            currentRowIndex++;
                        } 
                        else 
                        {
                            gridRow = eventToGridRowIndex[es.id_Person + '-' + es.id_Site];
                        }
                        gridRowToResourceIndex[gridRow] = id;

                        const isLastRowOfResource = personSites.slice(i).filter(nextEs => (mode === viewModes.Site ? nextEs.id_Person : nextEs.id_Site) !== searchId).length === 0;

                        return <DraggableEvent
                                    key={es.id_PersonSite}
                                    id={es.id_PersonSite}
                                    gridRow={gridRow}
                                    gridColumn={gridColumn}
                                    gridColumnSpan={gridColumnSpan}
                                    actualColumn={actualColumn}
                                    actualColumnSpan={actualColumnSpan}
                                    personSite={es}
                                    props={eventProps}
                                    className={classes}
                                    isActive={activePersonSiteId === es.id_PersonSite}
                                    moveResizeCell={moveResizeCell}
                                    onClick={(e, isActive) => {
                                        if (!isActive)
                                        {
                                            store.dispatch(activePersonSiteIdSet(es.id_PersonSite));
                                        }
                                        else
                                        {
                                            // store.dispatch(activePersonSiteIdSet(-1));
                                        }
                                    }}
                                    timeFrame={timeFrame}
                                    gridContainer={gridContainerRef}
                                    mode={mode}
                                    isNew={es.New}
                                    hasGap={isLastRowOfResource} />
                    });
                } else {
                    gridRowToResourceIndex[currentRowIndex] = id;
                    currentRowIndex++;
                }
            } else {
                gridRowToResourceIndex[currentRowIndex] = id;
                currentRowIndex++;
            }

            rowHeight = Math.max(rowHeight, 1);

            const startDateDiff = Math.round(moment(r.StartTime).diff(startDate, 'hours') / 12);
            let endDateDiff = 0;
            if(r.EndTime == null && 'EndTimeBudget' in r)
            {
                endDateDiff = Math.round(moment(r.EndTimeBudget).diff(startDate, 'hours') / 12);
            }
            else
            {
                endDateDiff = Math.round(moment(r.EndTime).diff(startDate, 'hours') / 12);
            }

            if (startDateDiff > 0) {
                prev.elements.push(
                    <div
                        key={id + '-l'}
                        className='bg-opacity-50 bg-gray-500'
                        style={{
                            gridRow: `${prev.index + 2} / span ${rowHeight}`,
                            gridColumn: `2 / span ${Math.min(startDateDiff, timeFrame * 2)}`,
                        }}
                    ></div>
                );
            }

            if (endDateDiff <= timeFrame * 2) {
                prev.elements.push(
                    <div
                        key={id + '-r'}
                        className='bg-opacity-50 bg-gray-500'
                        style={{
                            gridRow: `${prev.index + 2} / span ${rowHeight}`,
                            gridColumn: `${Math.max(2, endDateDiff + 2)} / ${(timeFrame * 2) + 2}`,
                        }}
                    ></div>
                );
            }

            prev.elements.push(
                (<div key={id + '-box'} className='flex flex-row border' style={{
                    gridRow: `${prev.index + 2} / span ${rowHeight}`,
                    gridColumn: '1'
                }}>
                    <PriorityButtons mode={mode} resourceId={id} priority={r.Priority} />
                    <div className='flex flex-col flex-grow cursor-pointer p-0.5' onClick={ (e) => siteClicked(e, r) }>
                        {'Progress' in r && typeof r.Progress !== 'undefined' ?
                        <div className="bg-gray-200 rounded-0">
                            <div className={'text-xs font-medium text-slate-1000 text-center h-1 leading-none ' + 
                                (r.Progress >= 100 ? 'bg-green-600' : 'bg-blue-600') } 
                                style={{ width: r.Progress > 100 ? "100%" : r.Progress.toString() + "%" }}>
                            </div>
                        </div>
                        : null}
                        <div key={id + '-col'} className='text-center my-2 select-none whitespace-wrap'>{r.Name}<br/>{'NameShort' in r ? r.NameShort : ''}</div>
                    </div>
                </div>
                ),
                (<RowClickLayer key={id + '-row'}
                    gridRow={`${prev.index + 2} / span ${rowHeight}`}
                    gridColumn={`2 / span ${timeFrame * 2}`}
                    startDate={startDate}
                    timeFrame={timeFrame}
                    recourceId={id}
                    mode={mode} />),
                ...personSitesHtml
            );
            // red weekend marking
            for (let i = 0; i < timeFrame; i++) 
            {
                let new_date = moment(startDate).add(i, 'days');
                if(new_date.day() === 0 || new_date.day() === 6)
                {
                    prev.elements.push(
                        <div key={id + '-we-blocker-' + ((i * 2) + 2)} className='bg-green-500/25' style={{
                            gridRow: `${prev.index + 2} / span ${rowHeight}`,
                            gridColumn: `${(i * 2) + 2} / span 2`
                        }}></div>
                    )
                }
            };

            const personIds = mode === viewModes.Person ? [id] : (typeof events[id] === 'undefined' ? [] : events[id].map(e => e.id_Person).filter((v, i, a) => a.indexOf(v) === i));
            const absenceRowHeight = mode === viewModes.Person ? rowHeight : 1;
            personIds.forEach(personId => {
                const gridRow = mode === viewModes.Person ? prev.index + 2 : eventToGridRowIndex[personId + '-' + id];
                const holidayByStartDate: Record<number, number> = {};

                if (typeof holidaysIndex[personId] !== 'undefined') {
                    const holidays = holidaysIndex[personId];
                    holidays.forEach(holiday => {
                        const startDateDiff = Math.round(moment(holiday.StartTime).diff(startDate, 'hours') / 12);
                        const endDateDiff = Math.round(moment(holiday.EndTime).diff(startDate, 'hours') / 12);
                        const startCol = Math.max(0, startDateDiff);
                        const endCol = Math.min(timeFrame * 2, endDateDiff);
                        const colSpan = endCol - startCol;
                        // check if holiday is in time frame
                        if (startDateDiff >= timeFrame * 2 || endDateDiff < 0) {
                            return;
                        }
                        holidayByStartDate[startCol] = colSpan;
                        prev.elements.push(
                            <div key={id + '-' + personId + '-' + holiday.id_Holidays + '-holiday-blocker-' + (startDateDiff + 2)} className='bg-red-400' style={{
                                gridRow: `${gridRow} / span ${absenceRowHeight}`,
                                gridColumn: `${startCol + 2} / span ${colSpan}`
                            }}></div>
                        )
                    });
                }

                if (typeof absencesIndex[personId] !== 'undefined') {
                    const absences = absencesIndex[personId];
                    let startDateMoment = moment(startDate);
                    const weekday = startDateMoment.weekday();
                    const weekdayKeys = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
                    const timeKeys = ['Morning', 'Afternoon'];
                    for (let i = 0; i < timeFrame * 2; i++) {
                        if (holidayByStartDate[i] !== undefined) {
                            i += holidayByStartDate[i];
                        }
                        const absence = absences[weekdayKeys[(Math.floor(i / 2) + weekday) % 7] + '_' + timeKeys[i % 2]];
                        const available = absence === undefined || absence.Available;
                        if(!available) {
                            prev.elements.push(
                                <div key={id + '-' + personId + '-day-blocker-' + (i + 2)} className='bg-red-400' style={{
                                    gridRow: `${gridRow} / span ${absenceRowHeight}`,
                                    gridColumn: `${i + 2} / span 1`
                                }}></div>
                            )
                        }
                    };
                }
            });

            prev.index += rowHeight;

            return prev;
        }, {
            elements: [],
            index: 0
        });

    const columns: string[] = [];
    for (let i = 0; i < timeFrame; i++) 
    {
        let new_date = moment(startDate).add(i, 'days');
        if(new_date.day() === 1)
        {
            columns.push('KW' + getKWFromDate(new_date).toString() + '\n' + new_date.format('dd'));
        }
        else
        {
            columns.push(new_date.format('DD.MM.') + '\n' + new_date.format('dd'));
        }
    }

    return (
        <div className='flex flex-col'>
            <div className='flex item-center justify-between my-5' onClick={(e) => store.dispatch(activePersonSiteIdSet(-1)) }>
                <h1 className='text-2xl font-bold'> {mode === viewModes.Site ? 'Arbeitsplanung Aufträge' : 'Arbeitsplanung Mitarbeiter'}</h1>
            </div>
            <div className='shadow-xl p-5'>
                <DndProvider backend={HTML5Backend}>
                    <div className='flex justify-between flex-row items-center' onClick={(e) => store.dispatch(activePersonSiteIdSet(-1)) }>
                        <div className='flex flex-row'>
                            <CustomDatePicker value={startDate.format('YYYY-MM-DD')} onChange={e => setStartDate(moment(e.target.value))} />
                        </div>
                        <div className='flex flex-wrap items-center space-x-3'>
                            <CustomButton Icon={CalendarIcon} className='whitespace-nowrap' onClick={() => setStartDate(moment().startOf('day'))}>Heute</CustomButton>
                            <CustomButton Icon={PlusCircleIcon} className='whitespace-nowrap' onClick={() => store.dispatch(timeFrameChanged(7))}>7 Tage</CustomButton>
                            <CustomButton Icon={PlusCircleIcon} className='whitespace-nowrap' onClick={() => store.dispatch(timeFrameChanged(21))}>3 Wochen</CustomButton>
                        </div>
                    </div>
                    <div ref={gridContainerRef} className='grid border' style={{
                            gridTemplateColumns: 'auto repeat(' + timeFrame * 2 + ', minmax(0, 1fr))'
                        }}>
                        <Link className='p-3 border select-none' to={mode === viewModes.Site ? '/sites-open' : '/persons'}>
                            {mode === viewModes.Site ? 'Aufträge' : 'Mitarbeiter'}
                        </Link>
                        {columns.map((column, i) => (
                            <div key={'col-' + i} onClick={(e) => 
                                {
                                    store.dispatch(activePersonSiteIdSet(-1));
                                    if(e.detail === 2) {
                                        const newDate = moment(startDate).add(i, 'days');
                                        setStartDate(newDate);
                                    }
                                }}
                                className={ (column.startsWith("KW") ? 'font-bold ' : '') + 'flex flex-col items-center content-center border select-none cursor-pointer' } 
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'clip',
                                    gridRow: '1',
                                    gridColumn: `${(i * 2) + 2} / span 2`
                                }}>
                                {column.indexOf('\n') === -1 ? [<div><br/></div>, <div>{column}</div>]: [<div>{column.split('\n')[0]}</div>, <div>{column.split('\n')[1]}</div>]}
                            </div>
                        ))}
                        {resourcesHtml}
                        <CustomDragLayer rows={numResources} cols={columns.length * 2} moveResizeCell={moveResizeCell}></CustomDragLayer>
                    </div>
                </DndProvider>
            </div>
        </div>
    );
}

export default Planner;
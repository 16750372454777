import moment from 'moment';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackButton, CreateButton } from '../components/CustomButton';
import CustomDatePicker from '../components/CustomDatePicker';
import CustomInput from '../components/CustomInput';
import { fetcher } from '../request';
import { eventToState, getDateFromKW, getEndDateDB, getKWFromDate, getStartDateDB } from '../helpers'
import CustomCheckbox from '../components/CustomCheckbox';

function SiteNew() {

    const { state } = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [nameShort, setNameShort] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('day').format('YYYY-MM-DD'));
    const [startKW, setStartKW] = useState('');
    const [endDate] = useState('');
    const [hours, setHours] = useState('');
    const [budget, setBudget] = useState('');
    const [exteriorWork, setExteriorWork] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const req = fetcher(process.env.REACT_APP_API_URL + '/Site', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Name: name,
                NameShort: nameShort,
                StartTime: getStartDateDB(startDate) || null,
                EndTime: getEndDateDB(endDate, 1) || null,
                BudgetHours: hours,
                Budget: budget,
                ExteriorWork: exteriorWork ? 1 : 0,
                Active: 1
            })
        });
        req.then(res => {
            if(res.status === 200)
            {
                navigate('..');
            }
        });

        console.log(name, startDate, endDate);
    }
    return (
        <div>
            <BackButton state = { state } navigate = { navigate } />
            <h1 className='text-2xl font-bold my-5'>Neuer Auftrag</h1>
            <CustomInput id='name' label='Name' type='text' onChange={eventToState(setName)} />
            <CustomInput id='name-short' label='Auftragsnummer' type='text' onChange={eventToState(setNameShort)} />
            <div className='flex space-x-5'>
                <CustomDatePicker id='start-date' type='date' label='Start Datum' value={startDate} onChange= { (e) => 
                {
                    setStartDate(e.target.value);
                    setStartKW(getKWFromDate(moment(e.target.value)).toString());
                }}  />
                <div className='block text-sm font-medium text-gray-500'> oder: </div>
                <CustomInput id='start-date' label='Kalenderwoche' type='number' value={startKW} onChange={ (e) => 
                { 
                    setStartKW(e.target.value); 
                    setStartDate(getDateFromKW(e.target.value)); 
                } } />
            </div>
            {/* <CustomDatePicker id='end-date' type='date' label='Enddatum' onChange={eventToState(setEndDate)} /> */}
            <CustomInput id='hours' label='Budgetierte Stunden' type='number' onChange={eventToState(setHours)} />
            <CustomInput id='budget' label='Budget (CHF)' type='number' onChange={eventToState(setBudget)} />
            <CustomCheckbox id='exterior-work' label='Malerarbeit aussen' onChange={(e) => setExteriorWork(e.target.checked)} />
            <CreateButton className='mt-6' onClick={handleSubmit}/>
        </div>
    );
}

export default SiteNew;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { db_Global } from '../../models';
import { fetcher } from '../../request';
import type { RootState, AppDispatch } from '../store';
import moment from 'moment';
import { getStartDateDB } from '../../helpers';

type GlobalsState = {
  globals: db_Global[],
  lastUpdated?: string,
};

const initialState: GlobalsState = {
  globals: [],
};

export const globalsSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    globalsUpdated: (state, action: PayloadAction<db_Global>) => {
        const mapper = (globals: db_Global) => globals.id_Globals === action.payload.id_Globals ? action.payload : globals;
        state.globals = state.globals.map(mapper);
        
    },
    globalsLoaded: (state, action: PayloadAction<db_Global[]>) => {
        state.globals = action.payload;
        state.lastUpdated = getStartDateDB(moment());
    },
  },
});

export const { globalsUpdated, globalsLoaded } = globalsSlice.actions;

export async function loadSettings(dispatch: AppDispatch, getState: () => RootState) {
    const { globals } = getState();
    if(globals.lastUpdated === undefined || (moment().diff(globals.lastUpdated)) > 60000)
    {
        const response = await fetcher(process.env.REACT_APP_API_URL + '/Globals');
        const globals = await response.json();
        dispatch(globalsLoaded(globals));
    }
}

export function updateSettings(global: db_Global) {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const newSetting = {
            ...global,
            id_Globals: undefined,
            Active: global.Active? '1' : '0',
        };
        const response = await fetcher(process.env.REACT_APP_API_URL + '/Globals/' + global.Name, {
            method: 'PUT',
            body: JSON.stringify(newSetting),
        });
        const updatedSetting = await response.json();
        dispatch(globalsUpdated(updatedSetting));
    };
}

export const selectGlobals = (state: RootState) => state.globals.globals;

export default globalsSlice.reducer;


import { db_Person, db_Site, viewModes } from '../models';
import { selectPersonIndex } from '../redux/persons/personsSlice';
import { selectPersonSitesBySiteIndex } from '../redux/personSites/personSitesSlice';
import { selectSites } from '../redux/sites/sitesSlice';
import Planner from './Planner';

function SitePlanning() {
    const sitesSelector = selectSites;
    const eventsSelector = selectPersonSitesBySiteIndex;
    const personIndexSelector = selectPersonIndex;

    return (
        <Planner<db_Site, db_Person> mode={viewModes.Site} resourcesSelector={sitesSelector} eventsSelector={eventsSelector} indexSelector={personIndexSelector} />
    );
}

export default SitePlanning;